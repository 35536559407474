import logo from "./logo.svg";
import "./App.css";
import { I18nextProvider } from "react-i18next";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Aboutpage from "./pages/Aboutpage";
import Contactpage from "./pages/Contactpage";
import Productspage from "./pages/Productspage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import ServicePage from "./pages/ServicePage";
import ServicePaintPage from "./pages/ServicePaintPage";
import LogoServicePage from "./pages/LogoServicePage";
import RepairServicePage from "./pages/RepairServicePage";
import { useEffect } from "react";
import ScrollToTop from "./utils/ScrooltoTop";
import i18next from "i18next";
import global_en from "./translation/en/global.json";
import global_fa from "./translation/fa/global.json";
import global_ar from "./translation/ar/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: { global: global_en },
    fa: { global: global_fa },
    ar: { global: global_ar },
  },
});

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage />,
    },
    {
      path: "/about",
      element: <Aboutpage />,
    },
    {
      path: "/contact",
      element: <Contactpage />,
    },
    {
      path: "/products",
      element: <Productspage />,
    },
    {
      path: "/products/:id",
      element: <ProductDetailsPage />,
    },
    {
      path: "/service",
      element: <ServicePage />,
    },
    {
      path: "/service/service-paint",
      element: <ServicePaintPage />,
    },
    {
      path: "/service/service-logo",
      element: <LogoServicePage />,
    },
    {
      path: "/service/service-repair",
      element: <RepairServicePage />,
    },
  ]);

  return (
    <>
      <I18nextProvider i18n={i18next}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </>
  );
}

export default App;
