import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import RevealX from "./motion/RevealX";
import Reveal from "./motion/Reveal";
import { useTranslation } from "react-i18next";

const TestimonialSection = () => {
  const [t,i18n] = useTranslation('global')
  return (
    <section  className="client_section layout_padding-bottom relative bg-white py-4">
      <div dir={i18n.language==='en' ? "rtl" : 'ltr'} className="container text-right">
        <RevealX>
          <h3 className="custom_heading text-end text-[1.75rem]">{t('your-trust')}</h3>
        </RevealX>
        <Reveal>
          <p className="mt-1 text-end">
            {t('trust-text')}
          </p>
        </Reveal>
      </div>
      <div className="container mx-auto   md:px-16">
        <Swiper
          
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          cl
        >
          <SwiperSlide>
            <Reveal>
              <div dir={i18n.language==='en' ? "rtl" : 'ltr'} className="row " >
                <div className="col-12">
                  <div className="detail_box mr-4">
                    <div className="img_box flex justify-center">
                      <img className="max-w-[140px]" src="/user.png" alt="" />
                    </div>
                    <div className="name_box">
                      <h5>{t('customer-name')}</h5>
                      <h6>{t('customer-job')}</h6>
                    </div>
                  </div>
                  <div className="detail_text flex items-center">
                    <p>
                      {t('customer-text')}
                    </p>
                  </div>
                </div>
              </div>
            </Reveal>
          </SwiperSlide>
          <SwiperSlide>
            <Reveal>
              <div dir={i18n.language==='en' ? "rtl" : 'ltr'} className="row " >
                <div className="col-12">
                  <div className="detail_box mr-4">
                    <div  className="img_box flex justify-center">
                      <img  className="max-w-[140px]" src="/user.png" alt="" />
                    </div>
                    <div className="name_box">
                      <h5>Sandy Den</h5>
                      <h6>Many Variations</h6>
                    </div>
                  </div>
                  <div className="detail_text flex items-center">
                    <p>
                    {t('customer-text')}
                    </p>
                  </div>
                </div>
              </div>
            </Reveal>
          </SwiperSlide>
          <SwiperSlide>
            <Reveal>
              <div dir={i18n.language==='en' ? "rtl" : 'ltr'} className="row " >
                <div className="col-12">
                  <div className="detail_box mr-4">
                    <div  className="img_box flex justify-center">
                      <img className="max-w-[140px]" src="/user.png" alt="" />
                    </div>
                    <div className="name_box">
                      <h5>Sandy Den</h5>
                      <h6>Many Variations</h6>
                    </div>
                  </div>
                  <div className="detail_text flex items-center">
                    <p>
                    {t('customer-text')}
                    </p>
                  </div>
                </div>
              </div>
            </Reveal>
          </SwiperSlide>
        </Swiper>
        <div className="swiper-button-prev carousel-control-prev">
          <span class="sr-only">Previous</span>
        </div>
        <div className="swiper-button-next carousel-control-next">
          <span class="sr-only">Next</span>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
