import React, { useEffect, useState } from "react";
import ProductCard from "./common/ProductCard";
import { httpReauest } from "../utils/httpRequest";
import Loading from "./common/Loading";
import Reveal from "./motion/Reveal";
import { useTranslation } from "react-i18next";

const NewProducts = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  const [t] = useTranslation('global')
  async function getData(params) {
    setloading(true);
    const res = await httpReauest("GET", "/product/popular?limit=4", {}, {});
    setData(res.data.data);
    setloading(false);
    console.log(res)
  }

  useEffect(() => {
    getData();
  }, []);
  // const testData = [{img:'/aircraft.jpg' ,name:'ایرکرافت'}]
  return (
    <section className=" layout_padding2 text-center bg-[#7ABAB4]/60">
      <div className="container">
        <div className="brand_heading">
          <Reveal head>
            <h3 className="custom_heading text-[1.75rem]">{t('new-products')}</h3>
          </Reveal>
          <Reveal>
            <p className=" mt-2">
              {t("new-product-text")}
            </p>
          </Reveal>
        </div>
      </div>
      <div className=" gap-3 sm:gap-5 grid-cols-2 mt-4 grid sm:grid-cols-3  lg:grid-cols-4 px-[10px] sm:px-10 lg:px-20 xl:px-36">
        {loading ? (
          <Loading />
        ) : (
          data.slice(0,4).map((item) => <ProductCard data={item} />)
        )}
      </div>
    </section>
  );
};

export default NewProducts;
