import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const SliderSection = () => {
  return (
    <section className="slider_section bg-no-repeat bg-right lg:bg-center bg-cover position-relative">
      <Swiper className="carousel slide ">
        <SwiperSlide className="">
          <div className="slider_item-box">
            <div className="slider_item-container">
              <div className="container ">
                <div className="flex flex-wrap justify-between">
                  <div className="md:w-[35%] w-full">
                    <div className="slider_img-box">
                      <div>
                        <img
                          src="/images/watch1.png"
                          alt=""
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:w-[50%] w-full">
                    <div className="slider_item-detail text-right">
                      <div>
                        <h1>طلا های خاص</h1>
                        <p>
                          این یک واقعیت ثابت شده است که خواننده هنگام تماشای
                          صفحه‌آرایش با محتوای قابل خواندن آن حواسش پرت می‌شود.
                          نکته استفاده از Lorem Ipsum این است که دارای توزیع کم
                          و بیش نرمال حروف است، برخلاف استفاده از «محتوا در
                          اینجا، محتوا در اینجا»، که آن را ایجاد می کند.
                        </p>
                        <div className="flex justify-center sm:justify-end">
                          <a href="" className="slider-btn1 ">
                            مشاهده بیشتر
                          </a>
                          <a href="" className="slider-btn2 ms-3">
                            ارتباط با ما
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="">
          <div className="slider_item-box">
            <div className="slider_item-container">
              <div className="container ">
                <div className="flex flex-wrap justify-between">
                  <div className="md:w-[35%] w-full">
                    <div className="slider_img-box">
                      <div>
                        <img
                          src="/images/watch.png"
                          alt=""
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:w-[50%] w-full">
                    <div className="slider_item-detail text-right">
                      <div>
                        <h1>طلا های خاص</h1>
                        <p>
                          این یک واقعیت ثابت شده است که خواننده هنگام تماشای
                          صفحه‌آرایش با محتوای قابل خواندن آن حواسش پرت می‌شود.
                          نکته استفاده از Lorem Ipsum این است که دارای توزیع کم
                          و بیش نرمال حروف است، برخلاف استفاده از «محتوا در
                          اینجا، محتوا در اینجا»، که آن را ایجاد می کند.
                        </p>
                        <div className="flex justify-center sm:justify-end">
                          <a href="" className="slider-btn1 ">
                            مشاهده بیشتر
                          </a>
                          <a href="" className="slider-btn2 ms-3">
                            ارتباط با ما
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="custom_carousel-control">
        <a
          className="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span className="sr-only">Next</span>
        </a>
      </div>
    </section>
  );
};

export default SliderSection;
