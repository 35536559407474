import React from "react";
import RevealX from "./motion/RevealX";
import Reveal from "./motion/Reveal";
import { useTranslation } from "react-i18next";

const WhySection = () => {
  const [t] = useTranslation("global");
  return (
    <section className="why_section layout_padding text-right">
      <div className="container ">
        <RevealX head>
          <h3 className="custom_heading text-end text-[1.75rem]">
            {t("why-avam")}
          </h3>
        </RevealX>
        <Reveal>
          <p className=" mt-1 text-end">{t("why-text")}</p>
        </Reveal>
      </div>
      <div className="container">
        <div className="row">
          <div className=" col-md-6">
            
            <Reveal>
              <div className="detail_box text-end ">
                <h5>{t("why-card-h")}</h5>
                <p>{t("why-card-p")}</p>
              </div>
            </Reveal>
            <RevealX head>
              <div className="img_box mr-2 ">
                <img src="images/feature-1.png" alt="" />
              </div>
            </RevealX>
          </div>
          <div className=" col-md-6">
           
            <Reveal>
              <div className="detail_box text-end">
                <h5>{t("why-card-h-1")}</h5>
                <p>{t("why-card-p-1")}</p>
              </div>
            </Reveal>
            <RevealX head>
              <div className="img_box mr-2">
                <img src="images/feature-2.png" alt="" />
              </div>
            </RevealX>
          </div>
          <div className=" col-md-6">
            
            <Reveal>
              {" "}
              <div className="detail_box text-end">
                <h5>{t("why-card-h-2")}</h5>
                <p>{t("why-card-p-2")}</p>
              </div>
            </Reveal>
            <RevealX head>
              <div className="img_box mr-2">
                <img src="images/feature-3.png" alt="" />
              </div>
            </RevealX>
          </div>
          <div className=" col-md-6">
            <Reveal>
              <div className="detail_box text-end">
                <h5>{t("why-card-h-3")}</h5>
                <p>{t("why-card-p-3")}</p>
              </div>
            </Reveal>
            <RevealX head>
              <div className="img_box mr-2">
                <img src="images/feature-4.png" alt="" />
              </div>
            </RevealX>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhySection;
