import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "./common/ProductCard";
import { httpReauest } from "../utils/httpRequest";
import { Autoplay } from "swiper/modules";
const SliderSimilier = ({  }) => {
  const [productId, setProductId] = useState([]);
  const [loading, setloading] = useState(false);

  async function getId() {
    setloading(true);
    const response = await httpReauest(
      "GET",
      `/product/popular`,
      {},
      {}
    );
    setProductId(response.data.data);
    setloading(false);
  }

  useEffect(() => {
    getId();
  }, []);

  return (
    <>
      <Swiper
      modules={[Autoplay]}
      loop
        autoplay={{ delay: 2000 }}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          800: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1100: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1400: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
        pagination={{
          clickable: true,
        }}
        
        
      >
        {productId.map((product, idx) => (
          <SwiperSlide className="pb-2">
            <ProductCard data={product} key={idx} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default SliderSimilier;
