import React, { useContext, useEffect, useState } from "react";
import { httpReauest } from "../utils/httpRequest";
import ProductCard from "./common/ProductCard";
import Loading from "./common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";

const Products = ({ formik }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState();
  const [hasmore, sethasmore] = useState(false);

  useEffect(() => {
  
      getData();
    
  }, []);

  // useEffect(() => {
  //   newfetchData();
  // }, [formik]);

  async function getData() {
    setLoading(true);
    await httpReauest("GET", "/product", {}, {}).then(({ data }) => {
      setProducts(data?.data);
      console.log(data)
    });
    setLoading(false);
  }

  // async function newfetchData() {
  //   setLoading(true);
  //   const page = 1;
  //   await httpReauest(
  //     "GET",
  //     `/product?page=${page}${
  //       formik.values.category && `&category=${formik.values.category}`
  //     }`,
  //     {},
  //     {}
  //   ).then(({ data }) => {
  //     setProducts(data.data);
  //     setLoading(false);

  //     if (data?.data?.length == 10) {
  //       sethasmore(true);
  //     } else {
  //       sethasmore(false);
  //     }
  //   });
  // }

  // async function fetchData() {
  //   const page = products.length / 10 + 1;
  //   await httpReauest(
  //     "GET",
  //     `/product?page=${page}${
  //       formik.values.category && `&category=${formik.values.category}`
  //     }`,
  //     {},
  //     {}
  //   ).then(({ data }) => {
  //     setProducts((cur) => [...cur, ...data.data]);

  //     if (data?.data?.length < 10) {
  //       sethasmore(false);
  //     }
  //   });
  // }

  return (
    <InfiniteScroll
      dataLength={products?.length ? products?.length : 10} //This is important field to render the next data
      // next={fetchData}
      hasMore={hasmore}
      loader={"Loading..."}
    >
      {loading ? (
        <Loading />
      ) : (
        <div className={"grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4"}>
          {products?.map((e) => (
            <span>
              <ProductCard data={e} />
            </span>
          ))}
        </div>
      )}
    </InfiniteScroll>
  );
};

export default Products;
