import React from "react";
import { Link } from "react-router-dom";

import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import { FaTelegram, FaWhatsapp } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const [t] = useTranslation('global')
  return (
    <div className=" px-3 text-white bg-[#111]  lg:pt-[30px] lg:px-[150px]  z-50">
      <section class="">
        <div class="max-w-screen-xl px-4 pt-12 pb-2 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
          <nav class="flex flex-wrap justify-center -mx-5 -my-2">
          <div class="px-5 py-2">
              <Link
                to="/service"
                class="text-base leading-6 text-gray-300 hover:text-gray-600"
              >
                {t('services')}
              </Link>
            </div>
            <div class="px-5 py-2">
              <Link
                to="/about"
                class="text-base leading-6 text-gray-300 hover:text-gray-600"
              >
                {t('about-us')}
              </Link>
            </div>
            <div class="px-5 py-2">
              <Link
                to="/contact"
                class="text-base leading-6 text-gray-300 hover:text-gray-600"
              >
                {t('contact-us')}
              </Link>
            </div>
            <div class="px-5 py-2">
              <Link
                to="/products"
                class="text-base leading-6 text-gray-300 hover:text-gray-600"
              >
                {t('products')}
              </Link>
            </div>
            <div class="px-5 py-2">
              <Link
                to="/"
                class="text-base leading-6 text-gray-300 hover:text-gray-600"
              >
                {t('home')}
              </Link>
            </div>
          </nav>
          <div class="flex justify-center mt-8 space-x-6">
            <Link
              to="https://t.me/drgoldco"
              target="_blank"
              class="text-gray-300 hover:text-gray-300"
            >
              <FaTelegram size={26} />
            </Link>
            <Link
              to="https://www.instagram.com/dr.g.o.l.d?igsh=emE2cHFuOXM4ZDY3"
              target="_blank"
              class="text-gray-300 hover:text-gray-300"
            >
              <FaInstagram size={26} />
            </Link>
            <Link
              to="https://wa.me/message/KX6CMZTR6Q3JO1"
              target="_blank"
              class="text-gray-300 hover:text-gray-300"
            >
              <FaWhatsapp size={26} />
            </Link>
          </div>
          <p className="text-center">{t('footer-addres')}</p>
          <p class="mt-8 text-base leading-6 text-center text-gray-500">
            {t('reserved')}
          </p>
        </div>
      </section>
    </div>
  );
};

export default Footer;
