import React from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import SliderVideo from "../components/SliderVideo";
import Footer from "../components/Footer";
import BestProducts from "../components/BestProducts";
import NewProducts from "../components/NewProducts";
import ServiceSection from "../components/ServiceSection";
import ScrollToTop from "../utils/ScrooltoTop";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Homepage = () => {
  const [t,i18n] = useTranslation('global')
 
  return (
    <div className="hero_area">
      <Header />
      <ScrollToTop />
      <SliderVideo />

      <div dir={i18n.language==='en' ? "rtl" : 'ltr'} className="bg">
        <AboutSection />
        <ServiceSection />
        {/* <BestProducts /> */}
        <NewProducts />
        <WhySection />
      </div>

      <TestimonialSection />
      <ContactUs />
      {/* <InfoSection /> */}
      <Footer />
    </div>
  );
};

export default Homepage;
