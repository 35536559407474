import React from "react";
import { useTranslation } from "react-i18next";
import { BsDiamondFill } from "react-icons/bs";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Reveal from "../components/motion/Reveal";
import RevealX from "../components/motion/RevealX";
import ScrollToTop from "../utils/ScrooltoTop";

const RepairServicePage = () => {
  const [t,i18n] = useTranslation('global')
  return (
    <div>
      <Header />
      <ScrollToTop  />
      <div>
        <img
          className="w-full lg:h-[500px]  object-cover"
          src="/repair2.jpg"
          alt=""
        />
      </div>
      <div dir={i18n.language === 'en' ? 'rtl' : 'ltr'} className="container">
        <h1 className="text-4xl custom_heading text-center my-5">
          {t('repair-polimer')}
        </h1>
        <RevealX>
          <p className="text-end mb-3">
            {t('repair-polimer-desc')}.
          </p>
        </RevealX>
        <Reveal>
          <p className="text-end">
            {t('repair-polimer-desc-2')} .
          </p>
        </Reveal>
        <div className="my-5 text-end">
          <Reveal>
            <h2 className=" custom_heading text-3xl mb-3">{t('projects')} </h2>
          </Reveal>
          <ul className="text-[15px]">
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>{t('repair-project')} </p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>{t('repair-project-1')} </p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>{t('repair-project-2')} </p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>  {t('repair-project-3')}  </p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>   {t('repair-project-4')}  </p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>     {t('repair-project-5')}    </p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>    {t('repair-project-6')}   </p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RepairServicePage;
