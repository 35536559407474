import React from "react";
import { useTranslation } from "react-i18next";
import { div, Link } from "react-router-dom";
import Reveal from "./motion/Reveal";
import RevealX from "./motion/RevealX";
import ServiceCard from "./ServiceCard";

const ServiceSection = () => {
  const [t] = useTranslation("global");
  return (
    <div className="container py-4  mt-[50px]  grid grid-cols-1 lg:grid-cols-2  gap-3 lg:gap-20">
      <div className="  col-span-1 order-2 lg:order-2 ">
        <RevealX>
          <h3 className="custom_heading text-end text-[1.75rem] mb-3">
            {" "}
            {t("services")}
          </h3>
        </RevealX>
        <RevealX>
          <div className="text-end">{t("services-sec-text")}.</div>
        </RevealX>
        <span className="flex justify-end my-3">
          <RevealX head>
            <Link
              to={"/service"}
              className="button-more w-fit bg-[#071777] px-8 py-2  text-white     border  border-[#071777] duration-100"
            >
              {t("learn-more")}
            </Link>
          </RevealX>
        </span>
      </div>
      <span className=" order-1  lg:order-1 my-auto">
        <Reveal>
          <div className="m-3">
            <img
              className="rounded my-auto  shadow "
              src="/pic.jpg"
              alt=""
            />
          </div>
        </Reveal>
      </span>
    </div>
  );
};

export default ServiceSection;
