import React from "react";
import { useTranslation } from "react-i18next";
import Reveal from "./motion/Reveal";

const ContactUs = () => {
  const [t,i18n] = useTranslation('global')
  return (
    <>
    
      <section class="contact_section layout_padding">
        
        <Reveal head>
          <h2 class="custom_heading text-center text-[2rem]">
            {t('contact-us-now')}
          </h2>
        </Reveal>

        <div class="container mx-auto md:px-16 lg:px-40 mt-5 pt-5">
          <form dir={i18n.language === 'en' ? 'ltr' : "rtl"} action="">
            <Reveal>
              <div>
                <input type="text" placeholder={t('name')} />
              </div>
            </Reveal>
            <Reveal>
              <div>
                <input type="email" placeholder={t("email")} />
              </div>
            </Reveal>
            <Reveal>
              <div>
                <input type="text" placeholder={t("phone")} />
              </div>
            </Reveal>
            <Reveal>
              <div>
                <input type="text" class="message-box" placeholder={t('text')} />
              </div>
            </Reveal>
            <Reveal head>
              <div class="flex justify-center mt-5 pt-5">
                <button>{t('send')}</button>
              </div>
            </Reveal>
          </form>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
