import React from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import TeamComponent from "../components/TeamSection";
import Filter from "../components/Filter";
import { useFormik } from "formik";
import Products from "../components/Products";
import Footer from "../components/Footer";
import ScrollToTop from "../utils/ScrooltoTop";
import { useTranslation } from "react-i18next";

const Productspage = () => {
  const formik = useFormik({
    initialValues: {
      year_start: "",
      year_end: "",
      min_price: "",
      max_price: "",
      category: "",
      haveImg: "",
      onFire: "",
      search_key: "",
      search_type: "product",
    },
  });
  const [t] = useTranslation('global')
  return (
    <div className="  ">
      <Header />
      <ScrollToTop />
      <div
        dir="rtl"
        className="grid container lg:grid-cols-4 gap-8 w-full py-8 px-[10px] lg:px-10 xl:px-12 bg-white text-right"
      >
        {/* <div className="lg:col-span-1">
          <Filter formik={formik} />
        </div> */}
        <div className="lg:col-span-4">
          <span className="text-center text-[14px] lg:text-[17px]">
            <h2 className="font-bold text-[28px] md:text-[40px]  2xl:text-[50px] ">
              {t('products')}
            </h2>
            <p>
              {t('product-p')}
            </p>
            <p>
              {t('product-p-1')}
            </p>
          </span>
          <div className="mt-5">
            <Products formik={formik} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Productspage;
