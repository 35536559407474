import React from "react";
import { useTranslation } from "react-i18next";

const TeamComponent = () => {
  const [t] = useTranslation('global')
  return (
    <section className="text-gray-700 body-font border-t border-gray-200">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="sm:text-3xl text-2xl font-bold title-font mb-4 text-gray-900">
            {t('our-team')}
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            {t('our-team-text')}.
          </p>
        </div>
        <div className="flex flex-wrap -m-2">
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="https://dummyimage.com/80x80/edf2f7/a5afbd"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  {t('teammate')}
                </h2>
                <p className="text-gray-500">{t('teammate-position')}</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="https://dummyimage.com/84x84/edf2f7/a5afbd"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  {t('teammate-1')}
                </h2>
                <p className="text-gray-500">{t('teammate-position-1')}</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="https://dummyimage.com/88x88/edf2f7/a5afbd"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  {t('teammate-2')}
                </h2>
                <p className="text-gray-500">{t('teammate-position-2')}</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="https://dummyimage.com/90x90/edf2f7/a5afbd"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">{t('teammate-3')}</h2>
                <p className="text-gray-500">{t('teammate-position-3')}</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="https://dummyimage.com/94x94/edf2f7/a5afbd"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  {t('teammate-4')}
                </h2>
                <p className="text-gray-500">{t('teammate-position-4')}</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="https://dummyimage.com/98x98/edf2f7/a5afbd"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  {t('teammate-5')}
                </h2>
                <p className="text-gray-500">{t('teammate-position-5')}</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="https://dummyimage.com/100x90/edf2f7/a5afbd"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  {t('teammate-6')}
                </h2>
                <p className="text-gray-500">{t('teammate-positin-6')}</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="https://dummyimage.com/104x94/edf2f7/a5afbd"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  {t('teammate-7')}
                </h2>
                <p className="text-gray-500">{t('teammate-positin-7')}</p>
              </div>
            </div>
          </div>
          <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="https://dummyimage.com/108x98/edf2f7/a5afbd"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 title-font font-medium">
                  {t('teammate-8')}
                </h2>
                <p className="text-gray-500">{t('teammate-position-8')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamComponent;
